import { Validators } from "@with-nx/hooks-n-helpers";
import moment from "moment";
import { GetStartedDetails } from "./get-started-form";

export const isEmpty = (value?: string) =>
  !value?.length || value === undefined || value === null;

export const hasTwoWords = (value?: string) => value?.includes(" ");

export const hasSelectedOption = (value?: string) =>
  !isEmpty(value) && String(value).length >= 1;

export const validators: {
  [key: string]: any;
} = {
  name: (value?: string) =>
    isEmpty(value) || !hasTwoWords(value) ? "Please enter your full name" : "",
  email: (value?: string) =>
    !Validators.isValidEmailAddress(value || "")
      ? "Please enter a valid email address"
      : "",
  phone_number: (value?: string) =>
    isEmpty(value) ? "Please enter a valid phone number" : "",
  organization: (value?: string) =>
    isEmpty(value) ? "Please enter a valid organization name" : "",
  organization_type: (value?: string) => !!hasSelectedOption(value),
  region: (value?: string) => (isEmpty(value) ? "Required" : ""),
  state: (value?: string, region?: string) => {
    return region === "🇺🇸 United States" || region === "🇨🇦 Canada"
      ? !isEmpty(value)
      : true;
  },
  show: (value?: string) =>
    isEmpty(value) ? "Please enter your show name" : "",
  opening_night: (value?: string, closingNight?: string) => {
    if (isEmpty(value)) {
      return "Please select an Opening Night date";
    }

    if (!moment(value, "YYYY-MM-DD", true).isValid()) {
      return "Opening night date is not valid.";
    }

    if (moment(value).isBefore(moment())) {
      return "Opening Night date must be in the future";
    }

    if (
      !isEmpty(closingNight) &&
      moment(closingNight).isBefore(moment(value))
    ) {
      return "Closing night date must be greater or equal than Opening night date.";
    }

    return undefined;
  },
  closing_night: (value?: string, openingNight?: string) => {
    if (isEmpty(openingNight)) {
      return "Please select an Opening Night date";
    }

    if (isEmpty(value)) {
      return "Please select a Closing Night date";
    }

    if (!moment(value, "YYYY-MM-DD", true).isValid()) {
      return "Closing night date is not valid.";
    }

    if (moment(value).isBefore(moment(openingNight))) {
      return "Closing night date must be greater or equal than Opening night date.";
    }

    if (moment(value).isBefore(moment())) {
      return "Closing Night date must be in the future";
    }

    return undefined;
  },
  customer_type: (value?: string) => !isEmpty(value),
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<F>): void => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

export const countryRequiresState = (value: string) =>
  ["🇺🇸 United States", "🇨🇦 Canada"].includes(value);

export const getComplementaryValidationValue = (
  field: string,
  values: GetStartedDetails
) => {
  if (field === "state") return values?.region;

  if (field === "opening_night") return values?.closing_night;

  if (field === "closing_night") return values?.opening_night;

  return undefined;
};
