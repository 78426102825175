import { useRef, useState } from "react";
import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";
import {
  DebounceContainer,
  DesignedInput,
  DesignedInputProps,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { useOuterClick } from "@with-nx/simple-ui/molecules";
import { debounce } from "libs/simple-ui/organisms/src/lib/contact-form/helpers";

interface Suggestion {
  title: string;
}
interface AutocompleteProps extends DesignedInputProps {
  value?: string;
  mobile: boolean;
  onSearch: (value?: string) => Suggestion[];
  onInputChange: (value?: string) => void;
}

export const Autocomplete: FC<AutocompleteProps> = ({
  onInputChange,
  onSearch,
  value,
  mobile,
  ...props
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  useOuterClick(wrapper, () => _show(false));

  const [show, _show] = useState(false);
  const [active, _active] = useState(0);
  const [suggestions, _suggestions] = useState<Suggestion[]>([]);

  const handleSearch = (value?: string) => {
    if (value && value?.length < 3) return;
    const suggestions = onSearch(value);

    _active(0);
    _show(true);
    _suggestions(suggestions);
  };

  const handleClick = (value: string) => {
    _active(0);
    _suggestions([]);
    _show(false);
    onInputChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.code) {
      case "Enter":
        _active(0);
        _show(false);
        onInputChange(suggestions[active].title);
        break;
      case "ArrowUp":
        if (active === 0) return;
        _active(active - 1);
        break;
      case "ArrowDown":
        if (active - 1 === suggestions.length) return;
        _active(active + 1);
        break;
      case "Escape":
        _show(false);
        break;
      default:
        return;
    }
  };

  const highlightText = (value: string, term: string) => {
    if (!value || !term) return [];

    const parts = value.split(
      new RegExp(`(${term.split(" ").join("|")})`, "gi")
    );

    const matches = (part: string) =>
      term.split(" ").some((t) => t.toLowerCase() === part.toLowerCase());

    return parts.map((part, index) => (
      <Rule
        color="var(--black-base)"
        key={`text-${index}`}
        weight={matches(part) ? "700" : "500"}
      >
        {part}
      </Rule>
    ));
  };

  const open = show && value && suggestions?.length > 0;
  const suggestionsList = open && (
    <Box
      tag="ul"
      parse={`p:absolute w:100% c:#FFF t:${mobile ? "60" : "90"} z:999`}
      border="1px solid var(--hoover)"
      style={{
        borderRadius: "0 0 4px 4px",
      }}
      reference={wrapper}
    >
      <Rule
        parse="!ls d:block c:var(--blue-60) mb:10 pt:11 pl:30 pr:30"
        weight="700"
      >
        Suggested
      </Rule>
      {suggestions.map((suggestion, index) => {
        const text = highlightText(suggestion.title, value || "");
        const className = `autocomplete-li${active === index ? "-active" : ""}`;

        return (
          <Box
            key={`show-suggestion-${index}`}
            tag="li"
            parse="d:block"
            press={() => handleClick(suggestion.title)}
          >
            <div
              className={className}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
              }}
            >
              {text?.map((t) => t)}
            </div>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box parse="w:100% p:relative">
      <DebounceContainer
        value={value}
        change={(value) => {
          onInputChange(value);
          handleSearch(value);
        }}
        delay={300}
        render={({ value, set }) => (
          <DesignedInput
            {...props}
            value={value}
            change={(value) => {
              set(value);
            }}
            properties={{
              ...props?.properties,
              radius: open ? "5px 5px 0 0" : 5,
            }}
            native={{
              onKeyDown: handleKeyDown,
              ...props?.native,
            }}
            blur={debounce(() => {
              props?.blur?.();
              _show(false);
            }, 300)}
            type="autocomplete"
          />
        )}
      />
      {suggestionsList}
    </Box>
  );
};
